import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import PageHeader from '../../components/pageHeader'
import Teoritillfälle1 from '../../images/utbildningar/Teoritillfälle1.jpg'
import Teoritillfälle2 from '../../images/utbildningar/Teoritillfälle2.jpg'
import Teoritillfälle3 from '../../images/utbildningar/Teoritillfälle3.jpg'
import Teoritillfälle4 from '../../images/utbildningar/Teoritillfälle4.jpg'
import TeoriundervisningImg from '../../images/utbildningar/teoriundervisning.jpg'
const Teoriundervisning = () => (
  <Layout>
    <Seo title='Teoriundervisning' />
    <PageHeader text='Teoriundervisning' />

    <div className='container'>
      <div className='row justify-content-around'>

        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 mb-3'>
          <div>
            <h2>Så går det till</h2>
            <p>Teoriundervisningen är uppdelad på 4 tillfällen och undervisningen sker i vår lokal på trafikskolan. Varje tillfälle har ett speciellt tema så som landsvägskörning, människan, miljö, stadstrafik osv. Vill du börja direkt och missat kursstarten går det bra att börja på vilket tillfälle som helst. I vissa av våra paketerbjudanden ingår teoriundervisningen.</p>
          </div>

          <div>
            <h3>Utbildningsmaterial</h3>
            <p>Vi använder oss av Sveriges Trafikskolors Riksförbunds utbildningsmaterial. En bra förutsättning är att du har utbildningsmaterialet med dig till tillfällena, du kan få läsanvisningar före och efter varje tillfälle. Teoriutbildningen ska komplettera den kunskap som du redan har eller kan läsa dig till från utbildningsmaterialet.</p>
          </div>

          <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3 p-0'>
            <div className='container'>
              <div className='row'>
                <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3 p-0 removeWhenMobile'>
                  <img src={Teoritillfälle1} alt='Stadstrafik' className='img-fluid rounded' />
                </div>
                <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3 '>
                  <h4>Teoritillfälle 1: Stadstrafik</h4>
                  <ul>
                    <li className='mb-0'>Avsökning</li>
                    <li className='mb-0'>Vägmärken</li>
                    <li className='mb-0'>Regler</li>
                    <li className='mb-0'>Cirkulationsplatser</li>
                  </ul>
                </div>

                <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3 '>
                  <h4>Teoritillfälle 2: Landsväg</h4>
                  <ul>
                    <li className='mb-0'>Vägmärken & vägmarkeringar</li>
                    <li className='mb-0'>Motorväg & motortrafikled</li>
                    <li className='mb-0'>Järnvägskorsningar</li>
                    <li className='mb-0'>Av & påfarter</li>
                    <li className='mb-0'>Vilt</li>
                  </ul>
                </div>
                <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3 p-0 removeWhenMobile'>
                  <img src={Teoritillfälle2} alt='Landsväg' className='img-fluid rounded' />
                </div>

                <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3 p-0 removeWhenMobile'>
                  <img src={Teoritillfälle3} alt='Teoritillfälle 3' className='img-fluid rounded' />
                </div>
                <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3 '>
                  <h4>Teoritillfälle 3: Miljö & kompletteringar</h4>
                  <ul>
                    <li className='mb-0'>Eccodriving</li>
                    <li className='mb-0'>Släp</li>
                    <li className='mb-0'>Lasta bilen rätt</li>
                  </ul>
                </div>

                <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3 '>
                  <h4>Teoritillfälle 4: Bilen</h4>
                  <ul>
                    <li className='mb-0'>Krockskydd</li>
                    <li className='mb-0'>Stoppsträckan</li>
                    <li className='mb-0'>Mörkerkörning</li>
                    <li className='mb-0'>Garagelektion</li>
                    <li className='mb-0'>Säkerhetskontroll</li>
                  </ul>
                </div>
                <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3 p-0 removeWhenMobile'>
                  <img src={Teoritillfälle4} alt='Teoritillfälle 4' className='img-fluid rounded' />
                </div>

              </div>
            </div>
          </div>
        </div>
        {/*
        <div className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-3'>
          <div className='card border-0'>
            <img className='card-img-top mb-0' src={require('../../images/teoriundervisning.jpg')} alt='teoriundervisning' />
            <div className='card-body text-center rounded-bottom themeColorTransparent'>
              <p className='card-title text-white'>Boka teoirtillfälle här</p>
              <a href='#1' className='btn btn-light'>Boka</a>
            </div>
          </div>
        </div> */}

        <div className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-3 text-center'>
          <img src={TeoriundervisningImg} alt='teoriundervisning' className='img-fluid rounded' />
          <p className='mb-1 text-sm-center'>
            {/* <Link to='/e-handel/' className='btn btn-danger'>Boka teoirtillfälle här</Link> */}
            {/* <Link to='/boka-utbildning' className='btn btn-danger'>Boka teoirtillfälle här</Link> */}
        

          </p>
        </div>

      </div>
    </div>
  </Layout>
)

export default Teoriundervisning
